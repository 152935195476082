@font-face {
  font-family: "NeueMontreal-Light";
  src: url("./fonts/NeueMontreal-Light.otf") format("opentype");
}
@font-face {
  font-family: "NeueMontreal-LightItalic";
  src: url("./fonts/NeueMontreal-LightItalic.otf") format("opentype");
}
@font-face {
  font-family: "NeueMontreal-Regular";
  src: url("./fonts/NeueMontreal-Regular.otf") format("opentype");
}
@font-face {
  font-family: "NeueMontreal-RegularItalic";
  src: url("./fonts/NeueMontreal-RegularItalic.otf") format("opentype");
}
@font-face {
  font-family: "NeueMontreal-Medium";
  src: url("./fonts/NeueMontreal-Medium.otf") format("opentype");
}
@font-face {
  font-family: "NeueMontreal-MediumItalic";
  src: url("./fonts/NeueMontreal-MediumItalic.otf") format("opentype");
}
@font-face {
  font-family: "NeueMontreal-Bold";
  src: url("./fonts/NeueMontreal-Bold.otf") format("opentype");
}
@font-face {
  font-family: "NeueMontreal-BoldItalic";
  src: url("./fonts/NeueMontreal-BoldItalic.otf") format("opentype");
}

body {
  --black: #000000;
  --white: #FFFFFF;
  --orange: #FF4800;
  --bone: #DFD7C7;

  margin: 0;
  font-family: "NeueMontreal-Regular";
  color: var(--black);
  letter-spacing: .2px;

}

a {
  text-decoration: none;
  color: var(--orange);
}

a:hover {
  color: #ff4800bc;
}

h1 {
  font-size: 36px;
  font-family: "NeueMontreal-Medium"; 
  margin: 0;
  font-weight: normal;
}

h2 {
  font-weight: normal;
  font-family: "NeueMontreal-Medium"; 
}

.container {
  width: 100%;
  max-width: 1035px;
  margin: auto;
  padding: 0 50px;
}

main{
  min-height: calc(100vh - 387px);
  padding: 63px 50px 75px 50px;
  animation: fadeIn 1s;
  -webkit-animation: fadeIn 1s;
  -moz-animation: fadeIn 1s;
  -o-animation: fadeIn 1s;
  -ms-animation: fadeIn 1s;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-moz-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-webkit-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-o-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-ms-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@media (max-width: 670px) {
  h1{
    font-size: 26px;
  }
  .container {
    padding: 0 24px;
  }
  main{
    padding: 40px 24px 55px 24px;
    min-height: calc(100vh - 335px);
  }
  body{
    font-size: 14px;
  }
}

/* mobile 670px */

