.about{
    max-width: 765px;
    margin: auto;
}

.about h1 {
    animation: fadeIn 2s;
    -webkit-animation: fadeIn 2s;
    -moz-animation: fadeIn 2s;
    -o-animation: fadeIn 2s;
    -ms-animation: fadeIn 2s;
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}
  
@-moz-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@-webkit-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@-o-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@-ms-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

.about .description{
    background-color: var(--bone);
    padding: 50px;
    margin: 60px 0;
    line-height: 1.3;
}

.about .description div{
    max-width: 490px;
    margin: auto;
}

.company{
    font-size: 25px;
    font-family: "NeueMontreal-Medium"; 
    line-height: 1.2;
}

.about li{
    list-style: none;
    margin-bottom: 3px;
    display: flex;
}

.about ul{
    padding: 0;
}

.plus{
    color: var(--orange);
    display: inline-block;
    margin-right: 7px;
}

.about .recognition h2{
    margin-bottom: 10px;
}

@media (max-width: 670px) {
    .about .description{
        padding: 35px;
        margin: 45px 0;
    }
    h2{
        font-size: 22px;
    }
}