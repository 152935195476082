.locations{
    max-width: 935px;
    margin: auto;
}

.mapContainer{
    width: 100%;
    height: 500px;
}

.locations .details{
    display: flex;
    justify-content: space-between;
    margin-top: 65px;
}

.locations .location{
    width: 48%;
}

.locations h1{
    margin-bottom: 20px;
}

.locations .info{
    background-color: var(--bone);
    height: 104px;
    padding: 28px 46px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.locations .address{
    margin-bottom: 10px;
    margin-top: -5px;
}

@media (max-width: 600px) {
    .locations .details{
        flex-direction: column;
        margin-top: 0px;
    }
    .locations .location{
        margin-top: 40px;
        width: 100%;
    }
    .locations .info{
        padding: 20px 30px;
    }
}