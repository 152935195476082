.contact{
    max-width: 602px;
    margin: auto;
}

.contact .form-container{
    margin-top: 34px;
    padding: 50px 54px 40px 54px;
    background-color: var(--bone);
}

.contact form{
    display: flex;
    flex-direction: column;
}

.contact .field-row{
    margin-bottom: 15px;
}

.contact .field-row.with-columns{
    display: flex;
    justify-content: space-between;
}

.contact .field-column{
    width: 48%;
}

.contact button{
    border: none;
    background-color: var(--orange);
    color: white;
    width: 85px;
    align-self: flex-end;
    font-size: 16px;
    padding: 10px 0;
    margin-top: 40px;
    cursor: pointer;
}

.contact button:hover{
    background-color: #ff4800bc;
}

.contact input, .contact textarea{
    display: block;
    width: 100%;
    border: none;
    height: 33px;
    margin-top: 4px;
}

.contact textarea{
    height: 165px;
    font-family: inherit;
}

@media (max-width:495px) {
    .contact .form-container{
        margin-top: 30px;
        padding: 30px 34px 40px 34px;
    }
    .contact .field-row.with-columns{
        flex-direction: column;
    }
    .contact .field-column{
        width: 100%;
    }
    .contact .field-column:nth-of-type(1){
        margin-bottom: 15px;
    }
    .contact textarea{
        height: 300px;
    }
}
.thankyou{
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.thankyou h1{
    margin-bottom: 30px;
}