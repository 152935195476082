header {
    background-color: var(--bone);
    display: flex;
}
nav{
    width: 100%;
}
nav ul {
    display: flex;
    justify-content: flex-end;
    padding-top: 7px;
}
nav li {
    list-style: none;
    margin-left: 26px;
}
nav p {
    color: var(--black);
    font-size: 18px;
    margin-bottom: 5px;
}
nav p:hover {
    color: #000000bc;
}
.logo {
    width: 170px;
    padding: 35px 0;
}
.menu {
    display: flex;
    align-items: center;
}
nav a{
    display: inline-block;
}
.active{
    border-bottom: 1px solid var(--orange);
}

.menu .hamburger{
    border: none;
    background-color: transparent;
    cursor: pointer;
    margin-left: auto;
    display: none;
}

.menu .hamburger:hover{
    opacity: .5;
}

@media (max-width: 670px) {
    .logo{
        width: 137px;
    }
    .menu nav{
        display: none;
    }
    .menu .hamburger{
        display: block;
    }
}