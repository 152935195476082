.modal{
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 99;
    animation: fadeIn .3s;
    -webkit-animation: fadeIn .3s;
    -moz-animation: fadeIn .3s;
    -o-animation: fadeIn .3s;
    -ms-animation: fadeIn .3s;
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}
  
@-moz-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@-webkit-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@-o-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@-ms-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

.modal.closing{
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s .3s, opacity .3s linear;
}

.modal .modal-content{
    position: absolute;
    right: 0;
    background-color: white;
    z-index: 999;
}
.mobile-menu ul{
    flex-direction: column;
    padding: 32px 200px 30px 40px;
}

.mobile-menu nav li{
    margin-bottom: 15px;
}

.modal button{
    border: none;
    background-color: transparent;
    position: absolute;
    top: 42px;
    right: 27px;
    cursor: pointer;
}

.modal button:hover{
    opacity: .7;
}

.opacity-wrapper{
    position: absolute;
    background: black;
    height: 100%;
    width: 100%;
    opacity: .5;
    z-index: 998;
}
