.team{
    max-width: 876px;
    margin: auto;
}

.member{
    display: flex;
    align-items: center;
    background-color: var(--bone);
    margin-bottom: 63px;
}
.member:last-of-type{
    margin-bottom: 0;
}
.team img{
    width: 335px;
}
.team .bio{
    padding: 20px 70px; 
}

.team .description{
    margin: 20px 0 25px 0;
}

.team .contact{
    color: var(--orange);
}

.team .description, .team .contact{
    font-size: 13.8px;
    line-height: 1.3;
}

@media (max-width:905px) {
    .team .bio{
        padding: 20px 40px; 
    }
    .team h1{
        font-size: 28px;
    }
    .team .description{
        font-size: 12px;
        margin: 15px 0;
    }
    .team .contact{
        font-size: 12px;
    }
    .team img{
        width: 290px;
    }
}

@media (max-width:750px) {
    .team{
        max-width: 400px;
    }
    .team .bio{
        padding: 35px; 
    }
    .member{
        flex-direction: column;
        background-color: none;
    }
    .team .bio{
        background-color: var(--bone); 
    }
    .team img{
        width: 100%;
    }
}