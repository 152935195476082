footer {
    display: flex;
    background-color: var(--bone);
    padding: 30px 0;
    padding-bottom: 35px;
    font-size: 12px;
}

.footer-container{
    width: 100%;
    display: flex;
}

.dot-icon{
    width: 20px;
    margin-left: auto;
    align-self: flex-end;
}

.copyright{
    font-size: 10px;
    margin-top: 9px;
    margin-bottom: -3px;
}